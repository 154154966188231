<template>
  <transition name="slide-fade">
    <v-alert
      :value="show"
      border="left"
      colored-border
      :color="type"
      elevation="2"
      top
      right
      class="toast"
      prominent 
      absolute
    >
      <div :class="`${type}--text fw500 poppins f14 text-wrap`">
        <v-icon v-if="type === 'success'" color="success" left>mdi-check-circle</v-icon>
        <v-icon v-if="type === 'error'" color="error" left>mdi-alert-circle</v-icon>
        {{ text }}
      </div>
    </v-alert>
  </transition>
</template>

<script>
export default {
  props: {
    show: Boolean,
    text: String,
    type: String
  },
};
</script>
<style scoped>
.v-snack__content {
  border-left: 5px solid #85c751 !important;
  border-radius: inherit !important;
}

.slide-fade-enter-active {
  transition: all 8s ease-in;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(30px);
  opacity: 0;
}
</style>
